var product = {

	setMatchmakerButton: function() {
		$('body').on('click', '#matchmaker-button', function(e) {
			e.preventDefault();
			var href = $(this).attr('href');
			var combinationIds = product.getCombinationIds();
			if(combinationIds.length) {
				var combinations = combinationIds.join(',');
				href += `&combination_ids=${combinations}`;
				$(this).attr('href', href);
				window.location.href = href;
			}
			else
				window.location.href = href;
		});
	},

	getCombinationIds: function() {
		var ids = [];
		if(!$('#combined-section').hasClass('uk-hidden')) {
			$('.product-select.combination').each(function() {
				var val = $(this).val();
				ids.push(val);
			});

			if(!ids.length)
				$('#matchmaker-button').attr('disabled', 'disabled');
			else
				$('#matchmaker-button').removeAttr('disabled');

		}
		return ids;
	},

	getCombinationCounts: function() {
		if(!$('#combined-section').hasClass('uk-hidden')) {
			var counts = [];
			$('.product-element .product-quantity').each(function() {
				var val = $(this).val();
				if(val)
					counts.push(val);
			});

			return counts;
		}
	},

	getPrefilledName: function(productId, combinationIds, combinationValues, code, url) {
		var nameInput = $('input[name="name"]');
		if(!nameInput.val() || code == 'bol') {
			$('#name-spinner').show();
			if(code == 'internal')
				nameInput.attr('disabled', 'disabled');
			$.ajax({
				type: 'GET',
				url,
				data: { code, product_id: productId, combined_product_ids: combinationIds, combination_values: combinationValues },
				success: function (data) {
					var name = data.name;
					$('#name-spinner').hide();
					if(code == 'internal') {
						nameInput.removeAttr('disabled');
						nameInput.val(name);
					} else {
						if(name) {
							$('#bol-name-suggestion-container').removeClass('uk-hidden');
							$('#bol-name-suggestion').html(name);
							$('#bol-name-suggestion-input').val(name);
						} else
							$('#bol-name-suggestion-container').addClass('uk-hidden');

					}
				}
			});
		}
	},

	init: function() {
		this.setMatchmakerButton();
	}
}

export default product;
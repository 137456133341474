'use strict'

global.jQuery = global.$ = require('jquery');
require('./bootstrap');
require('select2');
require('trumbowyg');
require('./trumbowyg/cleanpaste');

import product from './product';

global.product = product
global.UIkit = require('uikit')
global.ApexCharts = require('apexcharts');
$.trumbowyg.svgPath = '/trumbowyg/icons.svg';


$(function() {
	 $('body').on('keyup', '#filters .select2-search__field', function (e) {
		if (e.key === 'Enter') {
			var form = $(this).closest('form');
			if(form)
				form.trigger('submit');
		}
	});

	$('body').on('submit', 'form', function (e) {
		$(this).find('.save-button').attr('disabled', 'disabled');
	});
})